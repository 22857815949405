<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="track_trace_adj">
        <div class="label_element_cover">
        <label>Enter</label>
        <span>
          <input
            type="text"
            placeholder="Receipt No/Customer Ref/AWB No"
            class="searchtext"
            v-model="receiptNo"
            @keyup.enter="searchReceiptNo"
            @keydown="nameKeydown($event)"
          />
          <!-- <button class="search-btn">
            <i class="material-icons">search</i>
          </button>-->
        </span>
        </div>
        <div class="label_element_cover">
        <label>Filter By Date</label>
        <flat-pickr
          class="date_for_pos_fixed"
          v-model="daterange"
          :config="configDate"
          placeholder="Date"
          name="birthdate"
          @on-close="dateChanged"
          @on-open="cleardatepicker"
        ></flat-pickr>
        </div>
        <div class="label_element_cover">
        <label>Filter By Status</label>
        <select v-model="status" @change="filterStatus">
          <option value disabled="disabled" selected="selected">Select status</option>
          <option value="all">All</option>
          <option value="Created">Created</option>
          <option value="Booked">Booked</option>
          <option value="Delivered">Delivered</option>
          <option value="Dispatched">Dispatched</option>
          <option value="Not Home">Not Home</option>
          <option value="PickedUp">PickedUp</option>
          <option value="Received">Received</option>
          <option value="RTS">RTS</option>
          <option value="Transmitted">Transmitted</option>
          <option value="WC">WC</option>
          <option value="Error">Error</option>
          <option value="Label Generated">Label Generated</option>
          <option value="Manifested">Manifested</option>
        </select>
        </div>
      </div>
    </div>
    <loader v-if="loading" />
    <section class="yellow_tables_list print_hide" v-if="!loading">
      <div class="prcessed_item" v-for="(data,i) in courier" :key="i">
        <div class="yellow_tables_wrapepr">
          <div class="two_buttons_container">
            <a class="bt_print" @click="searchConsignment(data)">Print</a>
            <a class="bt_print" @click="searchConsignment(data)" v-if="data.booking_type != 'Transport' ">Receipt</a>
            <a class="bt_print" @click="searchConsignmentTransport(data)" v-else>Receipt</a>
            <a class="bt_print"  v-if="data.airway_bill && (data.file_name != null) " :href="data.label_url" target="_blank">Print Label</a>

          </div>

          <div class="view_consignment_wrapper" v-if="data.booking_type != 'Transport' ">
            <section class="table_row row1">
              <div class="div_33">
                <img class="logo_img" src="../../assets/temp_logo_yellow_new.png" />
                <div class="under_logo_transmit">
                  <header class="small_header">CARRIER TRACKING NO</header>
                <div v-if="(data.booking_type != 'Batch Upload') && (data.airway_bill == data.booking_id)" class="small_body">{{data.booking_prefix}}{{data.airway_bill}}</div>
              <div v-else class="small_body">{{data.airway_bill}}</div> 
            
              </div>
              </div>
              <div class="div_33">
                <div class="middle_div">
                  <div class="double_contents">
                    <header class="small_header">DATE</header>
                    <div class="small_body" style="border-bottom:0">{{data.created_at | formatDate}}</div>
                    <header class="small_header">BRANCH</header>
                    <div class="small_body">{{data.branch.branch_id}}</div>
                  </div>
                  <div class="double_contents">
                    <header class="small_header">PIECES</header>
                    <div class="small_body"  style="border-bottom:0">{{data.item_qty}}</div>
                    <header class="small_header">WEIGHT</header>
                    <div class="small_body">{{data.item_weight}}</div>
                  </div>
                </div>
              </div>
              <div class="div_33">
                <header class="small_header">TRACKING NUMBER</header>
                <div class="small_body small_body_2">
                  <barcode tag="img" :value="data.booking_prefix + data.booking_id" />
                </div>
              </div>
            </section>
            <section class="table_row row2 pr">
              <div class="div_50">
                <header class="small_header">Sender/Pickup Address</header>
                <div class="height90 small_body">
                  <div class="single_row">
                    <div>Sender/Pickup Address: 
                      {{data.requester_name}},
                       <span v-if="data.pickup_desk">Desk# {{ data.pickup_desk }}, </span>
                        <span v-if="data.pickup_floor">Floor# {{ data.pickup_floor }}, </span>
                        {{ data.pickup_address_1 ? data.pickup_address_1 + ', ' : '' }}
									      {{ data.pickup_address_2 ? data.pickup_address_2 + ', ' : '' }}
									      {{ data.pickup_city ? data.pickup_city + ', ':''}}
                        {{ data.pickup_state ? data.pickup_state + ', ' : '' }}
                        {{ data.pickup_postal_code ? data.pickup_postal_code + ', ' : '' }}
                        {{ data.pickup_country }}
                    </div>
                    <!-- <div  v-if="data.booking_type != 'Transport' ">Sender/Pickup Address: 
                      {{data.requester_name}}
                    </div> 
                    <div v-else>Sender/Pickup Address: 
                      {{data.pickup_city + ", "}}
                    </div> -->
                    <div class="mt5">SHIPPER REF:{{data.reference_no}}</div>
                  </div>
                </div>
                <div class="small_body address pr">E-mail: info@dbsa.asia</div>
              </div>
              <div class="div_50">
                <header class="small_header">Receiver/Drop Address</header>
                <div class="small_body height90">
                  <div class="single_row">
                    <div>
                      Receiver/Drop Address: {{data.delivery_name}}
                      <span v-if="data.delivery_desk">Desk# {{ data.delivery_desk }}, </span>
                      <span v-if="data.delivery_floor">Floor# {{ data.delivery_floor }}, </span>
                      {{ data.delivery_address_1 ? data.delivery_address_1 + ', ' : '' }}
									    {{ data.delivery_address_2 ? data.delivery_address_2 + ', ' : '' }}
									    {{ data.delivery_city ? data.delivery_city + ', ' : '' }}
									    {{ data.delivery_state ? data.delivery_state + ', ' : '' }}
									    {{ data.delivery_postal_code ? data.delivery_postal_code + ', ' : '' }}
									    {{ data.delivery_country }}
                    </div>
                  </div>
                  <!-- <div class="single_row">
                    <div  v-if="data.booking_type != 'Transport' ">
                      Receiver/Drop Address:{{data.delivery_name}}
                    </div>
                    <div v-else>Receiver/Drop Address:{{data.delivery_city}}</div>
                  </div> -->
                </div>
                <div class="small_body address pr">Web: http://www.dbsa.asia</div>
              </div>
            </section>
          </div>



          <div class="view_consignment_wrapper" v-else>
            <section class="table_row row1">
              <div class="div_33">
                <img class="logo_img" src="../../assets/temp_logo_yellow_new.png" />
                <div class="under_logo_transmit">
                  <header class="small_header">VEHICLE NAME</header>
                  <div class="small_body">{{data.transport.vehicle_model}}</div>
                </div>
              </div>
              <div class="div_33">
                <div class="middle_div">
                  <div class="double_contents">
                    <header class="small_header">REQUEST DATE</header>
                    <div class="small_body">{{moment(data.request_date,"YYYY-MM-DD").format("MM-DD-YYYY")}}</div>
                    <header class="small_header">VEHICLE TYPE</header>
                    <div class="small_body" v-if="data.delivery_service== 'Shuttle'">Hatch Back</div>
                    <div class="small_body" v-if="data.delivery_service== 'VIP'">Sedan</div>
                    <div class="small_body" v-if="data.delivery_service== 'Limousine'">SUV </div>
                  </div>
                  <div class="double_contents">
                    <header class="small_header">PASSENGERS</header>
                    <div class="small_body">{{data.transport.no_of_passengers}}</div>
                    <header class="small_header">JOURNEY TYPE</header>
                    <div class="small_body">{{data.transport.trip_type}}</div>
                  </div>
                </div>
              </div>
              <div class="div_33">
                <header class="small_header">BOOKING NUMBER</header>
                <div class="small_body small_body_2">
                  <barcode tag="img" :value="data.booking_prefix + data.booking_id" />
                </div>
              </div>
            </section>
            <section class="table_row row2 pr">
              <div class="div_50">
                <header class="small_header">Sender/Pickup Address</header>
                <div class="small_body">
                  <div class="single_row">
                    <div  v-if="data.booking_type != 'Transport' ">Sender/Pickup Address: {{data.requester_name}}</div>
                    <div v-else>Sender/Pickup Address: {{data.pickup_city}}</div>
                    <!-- <div>SHIPPER REF:{{data.reference_no}}</div> -->
                  </div>
                </div>
                <div class="small_body address pr">E-mail: info@dbsa.asia</div>
              </div>
              <div class="div_50">
                <header class="small_header">Receiver/Drop Address</header>
                <div class="small_body">
                  <div class="single_row">
                    <div  v-if="data.booking_type != 'Transport' ">Receiver/Drop Address:{{data.delivery_name}}</div>
                    <div v-else>Receiver/Drop Address:{{data.delivery_city}}</div>
                  </div>
                </div>
                <div class="small_body address pr">Web: http://www.dbsa.asia</div>
              </div>
            </section>
          </div>



        </div>
      </div>
      <p v-if="!loading && courier.length == 0">No data found</p>
    </section>
    <paginate
      v-if="pages > 0"
      v-show="!loading"
      v-model="current_page"
      :page-count="pages"
      active-class="paginate-active"
      :click-handler="fetchCouriers"
      prev-link-class="paginate-previous"
      next-link-class="paginate-next"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :no-li-surround="true"
    ></paginate>
    <div class="black-overlay" v-if="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment
            @closeModal="viewconsignmentpopup_booking_id = ''"
            :booking_id="viewconsignmentpopup_booking_id"
            :booking_prefix="viewconsignmentpopup_booking_prefix"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="viewconsignmenttransportpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignmentJourney
            @closeModal="viewconsignmenttransportpopup_booking_id = '' "
            :booking_id="viewconsignmenttransportpopup_booking_id"
            :booking_prefix="viewconsignmenttransportpopup_booking_prefix"
            v-if="viewconsignmenttransportpopup_booking_id"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ViewConsignmentJourney from "./ViewConsignmentJourney.vue";
import ViewConsignment from "./ViewConsignment.vue";
import moment from "moment";

export default {
  name: "ViewProcessed",
  components: {
    flatPickr,
    ViewConsignment,
    ViewConsignmentJourney
  },
  data() {
    return {
      receiptNo: "",
      daterange:  moment(new Date()).format('YYYY-MM-DD'),
      status: "",
      enableTime: true,
      loading: true,
      current_page: 0,
      pages: 0,
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      showViewConsignment: false,
      courier: [],
      post_data: {
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
        value: "",
        statusfilter: ""
      },
      viewconsignmentpopup_booking_id: "",
      viewconsignmentpopup_booking_prefix: "",
      viewconsignmenttransportpopup_booking_prefix: "",
      viewconsignmenttransportpopup_booking_id:""
    };
  },
	filters: {
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD-MM-YYYY')
		},
	},
  created() {
    this.$emit("breadCrumbData", ["View Receipts "]);
    if (localStorage.view_summary) {
      let data = JSON.parse(localStorage.view_summary);
      if (data.booking_id) {
        this.receiptNo = data.booking_id;
        this.searchReceiptNo(data.booking_id);
        localStorage.removeItem("view_summary");
      }
    }
    this.fetchCouriers();
  },
  methods: {
      nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    cleardatepicker(){

      this.daterange="";
    },
    searchReceiptNo(receiptNo) {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },
    filterStatus(status) {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    fetchCouriers(page = 1) {
      this.current_page = page;
      this.loading = true;
      this.axios
        .post("/api/courier/" + page + "/listingAndFilter", this.post_data)
        .then(response => {
          console.log('response', response);
          // console.log(response);
          if (response.data.status == "ok") {
            // this.courier = response.data.couriers.list;
            this.courier = response.data.couriers.list.map(element => {
              console.log('element', element);
              // console.log(element);
              element.show_view_popup = false;
              element.label_url = element.airway_bill && element.file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.file_name : "";
              
              return element;
            });
            this.pages = response.data.couriers.pages;
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    searchConsignment(booking) {
      this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmentpopup_booking_id = booking.booking_id;
    },
    searchConsignmentTransport(booking) {
      this.viewconsignmenttransportpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmenttransportpopup_booking_id = booking.booking_id;
    }
  }
};
</script>
<style  lang="scss" >
.transmittal_slip_filter .track_trace_adj {
  display: flex;
}
.transmittal_slip_filter .track_trace_adj span {
  display: block;
  position: relative;
  button {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: none;
    right: 20px;
    top: 5px;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    i {
      display: inline;
      color: #06A5ED;
      margin: 0;
      line-height: 0;
      font-size: 24px;
    }
  }
}
.transmittal_slip_filter .track_trace_adj .searchtext {
  width: 360px;
  margin-left: 0;
}
.prcessed_item {
  margin-bottom: 20px;
}
section.yellow_tables_list {
  padding: 20px 0;
  .yellow_tables_wrapepr {
    width: 780px;
    height: auto;
    border: 10px solid #ddd;
    position: relative;
    .two_buttons_container {
      position: absolute;
      width: 120px;
      height: auto;

      right: -150px;
      top: 50%;
      margin-top: -50px;
      .bt_print {
        display: block;
        color: #000;
        background: #06A5ED;
        width: 120px;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 400;
      }
    }
    .view_consignment_wrapper section.table_row .div_33 .extra1 {
      height: 50px;
      background: Red;
    }
    .view_consignment_wrapper section.table_row.row2 {
      height: 190px;
    }
  }
}
.view_consignment_wrapper
  section.table_row.row1
  .under_logo_transmit
  .small_body {
  height: 28px;
}
.view_consignment_wrapper section.table_row.row2 .small_body.address.pr {
  height: 50px;
}
section.yellow_tables_list
  .yellow_tables_wrapepr
  .view_consignment_wrapper
  section.table_row.row2.pr {
  height: 145px;
}

.mt5{
  margin-top: 5px;
}

.height90{
  height: 90px !important;
}
</style>